import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert2";

let elements;
let stripe;
let clientSecret;

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["form"];
  static values = {
    clientSecret: String,
    returnUrl: String,
    loadingGif: String,
  };

  connect() {
    this.initializeStripe();
    this.setupStripeElements();
  }

  initializeStripe() {
    var stripePublickey =
      document.getElementById("stripe-public-key").dataset.stripeKey;
    stripe = Stripe(stripePublickey);
  }

  setupStripeElements() {
    const options = {
      clientSecret: this.clientSecretValue,
      // Fully customizable with appearance API.
      appearance: {
        theme: "minimal",
      },
      fields: {
        billingDetails: {
          address: {
            country: "never",
          },
        },
      },
    };

    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
    elements = stripe.elements(options);

    // Create and mount the Payment Element
    const paymentElement = elements.create("payment", options);
    paymentElement.mount("#payment-element");
  }

  async valid_payment_intent() {
    var requires_capture = false
    await stripe.retrievePaymentIntent(this.clientSecretValue).then(function(response) {
      if (response.paymentIntent && response.paymentIntent.status === 'requires_capture') {
        requires_capture = true
      }
    });
    return requires_capture
  }

  async makePayment(event) {
    event.preventDefault();

    var formTarget = this.formTarget;

    if (this.validPersonalInformation()) {
      return;
    }

    this.swal_processing_popup()

    var requires_capture = await this.valid_payment_intent()
    if (requires_capture){
      formTarget.requestSubmit();
      return
    }

    stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: "US",
              },
            },
          },
        },
      })
      .then(function (result) {
        window.result = result;
        if (result.error) {
          swal.fire("Oops", result.error.message, "error").then((result) => {
            if (result) window.history.back()
          });
        } else {
          var paymentIntent = result.paymentIntent;
          switch (paymentIntent.status) {
            case "requires_capture":
              // remove data-action from button to submit the form
              formTarget.requestSubmit();
              break;

            case "requires_payment_method":
              swal.fire("Oops", "Payment failed. Please try another payment method.", "error");
              break;

            default:
              swal.fire("Oops", "Something went wrong.", "error");
              break;
          }
        }
      });
  }

  swal_processing_popup(){
    swal.fire({
      title: "Processing Payment",
      text: "Please wait while we process your payment...",
      imageUrl: this.loadingGifValue,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  validPersonalInformation() {
    var error = false;
    const firstName = document.getElementById(
      "payment_payer_attributes_first_name"
    );
    const lastName = document.getElementById(
      "payment_payer_attributes_last_name"
    );
    const email = document.getElementById("payment_payer_attributes_email");
    const confirm_email = document.getElementById("confirm_email");

    if (!firstName.value) {
      if (firstName.nextElementSibling) firstName.nextElementSibling.remove();
      $("<span style='color: red;'>is required</span>").insertAfter(firstName);
      error = true;
    }

    if (!lastName.value) {
      if (lastName.nextElementSibling) lastName.nextElementSibling.remove();
      $("<span style='color: red;'>is required</span>").insertAfter(lastName);
      error = true;
    }

    if (!email.value || !this.validateEmail(email.value)) {
      if (email.nextElementSibling) email.nextElementSibling.remove();
      $(
        "<span style='color: red;'>Please enter a valid email</span>"
      ).insertAfter(email);
      error = true;
    }

    if (email.value.toLowerCase() != confirm_email.value.toLowerCase()) {
      if (confirm_email.nextElementSibling) confirm_email.nextElementSibling.remove();
      $(
        "<span style='color: red;'>Confirmation Email does not match</span>"
      ).insertAfter(confirm_email);
      error = true;
    }

    return error;
  }

  validateEmail(email) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }
}
